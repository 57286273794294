import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { NavigationRouteNames } from '../app.routes';
import { AuthService } from './auth.service';

export const authGuard: CanActivateFn = () => {
  const auth = inject(AuthService);
  const router = inject(Router);

  return auth.getCurrentUser().pipe(
    take(1),
    map((user) => {
      if (user) return true;
      return router.createUrlTree([NavigationRouteNames.SIGN_IN]);
    }),
    catchError(() => of(router.createUrlTree([NavigationRouteNames.SIGN_IN]))),
  );
};
