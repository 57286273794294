import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';
import { provideNativeDateAdapter } from '@angular/material/core';
import {
  MatDateRangeInput,
  MatDateRangePicker,
  MatDatepickerInputEvent,
  MatDatepickerToggle,
  MatEndDate,
  MatStartDate,
} from '@angular/material/datepicker';
import { MatDivider } from '@angular/material/divider';
import {
  MatFormField,
  MatHint,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import * as Highcharts from 'highcharts';
import { DateTime } from 'luxon';
import { ChartData } from '../../models/portfolios';
import { PortfoliosService } from '../../services/portfolios.service';

@Component({
  selector: 'app-portfolio-report',
  standalone: true,
  imports: [
    AsyncPipe,
    MatButton,
    MatChipListbox,
    MatChipOption,
    MatDatepickerToggle,
    MatDateRangeInput,
    MatDateRangePicker,
    MatDivider,
    MatEndDate,
    MatFormField,
    MatHint,
    MatInput,
    MatLabel,
    MatStartDate,
    MatSuffix,
    ReactiveFormsModule,
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './portfolio-report.component.html',
})
export class PortfolioReportComponent {
  protected portfolioFormControl = new FormControl('', [Validators.required]);

  private startDate?: string;
  private endDate?: string;

  constructor(private readonly portfoliosService: PortfoliosService) {}

  protected selectStartDate({ value }: MatDatepickerInputEvent<Date>) {
    if (!value) return;
    this.startDate = DateTime.fromJSDate(value).toISODate() ?? undefined;
  }

  protected selectEndDate({ value }: MatDatepickerInputEvent<Date>) {
    if (!value) return;
    this.endDate = DateTime.fromJSDate(value).toISODate() ?? undefined;
  }

  protected apply() {
    const portfolioId = this.portfolioFormControl.value;
    if (!portfolioId) return;

    this.portfoliosService
      .getReport(portfolioId, this.startDate, this.endDate)
      .subscribe((chartData) => chartData && this.loadChart(chartData));
  }

  private loadChart({ dates, values }: ChartData): void {
    Highcharts.chart('chart', {
      chart: {
        type: 'line',
      },
      title: {
        text: 'Portfolio Performance',
      },
      xAxis: {
        categories: dates,
      },
      yAxis: {
        title: {
          text: 'Market value',
        },
      },
      series: [
        {
          name: 'Dates',
          data: values,
          type: 'line',
        },
      ],
      tooltip: {
        formatter: function () {
          return 'The value for <b>' + this.x + '</b> is <b>' + this.y + '</b>';
        },
      },
    });
  }
}
