<mat-card>
  <mat-card-header>
    <mat-card-title>Sign In</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="signInForm" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input
          type="email"
          matInput
          formControlName="email"
          placeholder="Ex. pat@example.com"
          required
        />
        <mat-hint>Ex. pat&#64;example.com</mat-hint>
        @if (
          signInForm.get('email')?.hasError('email') &&
          !signInForm.get('email')?.hasError('required')
        ) {
          <mat-error>Please enter a valid email address</mat-error>
        }
        @if (signInForm.get('email')?.hasError('required')) {
          <mat-error>Email is required</mat-error>
        }
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Password</mat-label>
        <input type="password" matInput formControlName="password" required />
        @if (signInForm.get('password')?.hasError('required')) {
          <mat-error>Password is required</mat-error>
        }
      </mat-form-field>
      <button
        type="submit"
        mat-raised-button
        class="!block"
        color="primary"
        [disabled]="signInForm.invalid"
        >Sign In</button
      >
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button [routerLink]="['/', NavigationRouteNames.SIGN_UP]"
      >Go to Sign Up</button
    >
  </mat-card-actions>
</mat-card>
