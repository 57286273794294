import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PortfolioReport } from '@yeekatee/client-api-angular';
import { Observable, map } from 'rxjs';
import { ENVIRONMENT } from '../app.config';
import { Environment } from '../models/environment';
import { ChartData } from '../models/portfolios';

@Injectable({
  providedIn: 'root',
})
export class PortfoliosService {
  constructor(
    private readonly http: HttpClient,
    @Inject(ENVIRONMENT) private readonly environment: Environment,
  ) {}

  getReport(
    portfolioId: string,
    startDate?: string,
    endDate?: string,
  ): Observable<ChartData | undefined> {
    return this.http
      .get<PortfolioReport>(this.environment.apiDomainName + '/report', {
        params: new HttpParams({
          fromObject: {
            keys: [portfolioId],
            ...(startDate ? { startDate } : {}),
            ...(endDate ? { endDate } : {}),
          },
        }),
      })
      .pipe(
        map((r) =>
          r.aggregation?.reportValues?.[0].timeSeries?.reduce(
            (acc, ts) => {
              if (!ts.date || ts.marketValue == undefined) return acc;

              acc.dates.push(ts.date);
              acc.values.push(ts.marketValue);
              return acc;
            },
            { dates: [], values: [] } as ChartData,
          ),
        ),
      );
  }
}
