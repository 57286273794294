import { Component, NgZone } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButton } from '@angular/material/button';
import {
  MatCard,
  MatCardActions,
  MatCardContent,
  MatCardHeader,
  MatCardTitle,
} from '@angular/material/card';
import {
  MatError,
  MatFormField,
  MatHint,
  MatLabel,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { Router, RouterLink } from '@angular/router';
import { first } from 'rxjs';
import { NavigationRouteNames } from '../../app.routes';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-sign-up',
  standalone: true,
  imports: [
    MatButton,
    MatCard,
    MatCardActions,
    MatCardContent,
    MatCardHeader,
    MatCardTitle,
    MatError,
    MatFormField,
    MatHint,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    RouterLink,
  ],
  templateUrl: './sign-up.component.html',
})
export class SignUpComponent {
  protected readonly signUpForm = this.fb.group({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    confirmPassword: new FormControl('', [Validators.required]),
    code: new FormControl(''),
  });

  protected readonly NavigationRouteNames = NavigationRouteNames;
  protected askForCode = false;

  constructor(
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly authService: AuthService,
    private readonly ngZone: NgZone,
  ) {}

  protected onSubmit(): void {
    const { email, password, confirmPassword, code } = this.signUpForm.value;
    if (!email || !password || !confirmPassword || password !== confirmPassword)
      return;

    if (!code) {
      this.authService
        .signUp({
          email,
          password,
        })
        .pipe(first())
        .subscribe(() => {
          this.ngZone.run(() => (this.askForCode = true));
        });
    } else {
      this.authService
        .confirmSignUp(email, code)
        .pipe(first())
        .subscribe(() => this.router.navigate([NavigationRouteNames.HOME]));
    }
  }
}
