import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Sha256 } from '@aws-crypto/sha256-browser';
import { SignatureV4 } from '@smithy/signature-v4';
import { HeaderBag, QueryParameterBag } from '@smithy/types';
import { Auth } from 'aws-amplify';
import { from, map, switchMap } from 'rxjs';
import { ENVIRONMENT } from '../app.config';

export const sigv4Interceptor: HttpInterceptorFn = (req, next) => {
  const region = inject(ENVIRONMENT).aws.Auth.region as string;
  return from(Auth.currentCredentials()).pipe(
    map(
      (credentials) =>
        new SignatureV4({
          applyChecksum: true,
          uriEscapePath: true,
          credentials: credentials,
          sha256: Sha256,
          service: 'execute-api',
          region,
        }),
    ),
    switchMap((sigv4) => {
      const { method, urlWithParams, body } = req;
      const {
        protocol,
        hostname,
        port: portString,
        pathname: path,
        searchParams,
        username,
        password,
        hash: fragment,
      } = new URL(urlWithParams);

      const headers: HeaderBag = {
        Host: hostname,
      };
      for (let key of req.headers.keys()) {
        const value = req.headers.get(key);
        if (value) {
          headers[key] = value;
        }
      }

      const query: QueryParameterBag = {};
      for (let [key, value] of searchParams) {
        const param = query[key];
        if (param) {
          query[key] = [...[param].flat(), value];
        } else {
          query[key] = value;
        }
      }

      const port = portString ? parseInt(portString) : undefined;

      return sigv4.sign({
        method,
        headers,
        body,
        protocol,
        hostname,
        port,
        path,
        query,
        username,
        password,
        fragment,
      });
    }),
    switchMap((signedRequest) =>
      next(
        req.clone({
          body: signedRequest.body,
          setHeaders: {
            ...signedRequest.headers,
            host: signedRequest.hostname,
          },
        }),
      ),
    ),
  );
};
