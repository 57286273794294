import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import { ApplicationConfig, InjectionToken } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';
import { Environment } from './models/environment';
import { sigv4Interceptor } from './services/sigv4.interceptor';

export const ENVIRONMENT = new InjectionToken<Environment>('environment');

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideClientHydration(),
    provideHttpClient(withFetch(), withInterceptors([sigv4Interceptor])),
    provideRouter(appRoutes),
    { provide: ENVIRONMENT, useValue: environment },
  ],
};
