import { bootstrapApplication } from '@angular/platform-browser';
import { Amplify } from 'aws-amplify';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

// Set up Amplify
Amplify.configure(environment.aws);

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
