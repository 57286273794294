import { Injectable } from '@angular/core';
import { CodeDeliveryDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { User } from 'aws-cdk-lib/aws-iam';
import { from, throwError } from 'rxjs';
import { ulid } from 'ulid';
import { Credentials } from '../models/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /**
   * Retrieve the groups from the ID token.
   *
   * @private
   */
  private async getGroups(): Promise<string[]> {
    const session = await Auth.currentSession();
    return session.getIdToken().payload['cognito:groups'];
  }

  private async doGetCurrentUser(bypassCache: boolean): Promise<User> {
    const user = await Auth.currentUserPoolUser({ bypassCache });

    // Start getting the current ID token here,
    // since later we may have to get attributes too in parallel.
    const groupsPromise = this.getGroups();

    // The member field 'attributes' is not exposed in the CognitoUser definition,
    // however it is at runtime. Therefore, to cope with deprecation of 'attributes'
    // we add a fallback, where we call the actual service.
    if (!user.attributes) {
      // TODO add logger message so we are aware of the deprecation.
      const info = await Auth.currentUserInfo();
      user.attributes = info.attributes;
    }

    // Now we can resolve the ID token and check the groups
    const groups = await groupsPromise;

    return {
      id: user.getUsername(),
      groups,
      ...user.attributes,
    };
  }

  /**
   * Retrieve the current user, including its groups from the ID token.
   *
   * @param refresh whether to always call Cognito, even if a local token exists
   */
  getCurrentUser(refresh = true) {
    return from(this.doGetCurrentUser(refresh));
  }

  private async doSignIn(
    credentials: Credentials,
  ): Promise<{ cognitoUser: CognitoUser; groups: string[] }> {
    const cognitoUser = await Auth.signIn(
      credentials.email,
      credentials.password,
    );

    const groups = await this.getGroups();

    return { cognitoUser, groups };
  }

  /**
   * Sign in and store the email locally
   *
   * @param credentials email and password
   */
  signIn(credentials: Credentials) {
    return from(this.doSignIn(credentials));
  }

  /**
   * Create Cognito user.
   * Set a ULID as email. The end user will be able to login via Email
   * or with a preferred email
   *
   * @param credentials the chosen email and password
   */
  private async doSignUp(credentials: Credentials) {
    const result = await Auth.signUp({
      username: ulid(),
      password: credentials.password,
      attributes: {
        email: credentials.email,
        locale: 'en',
      },
      autoSignIn: { enabled: true },
    });

    return result;
  }

  /**
   * Sign Up AWS Cognito
   * Setting Cognito user during initial configuration upon first login
   *
   * @param credentials
   */
  signUp(credentials: Credentials) {
    if (!credentials.password) throwError(() => new Error('Empty Password'));
    if (!credentials.email) throwError(() => new Error('Empty Email'));

    return from(this.doSignUp(credentials));
  }

  /**
   * Confirm the sign up using the code received via email
   *
   * @param email
   * @param code the one-time code received via mail
   */
  confirmSignUp(email: string, code: string) {
    return from(Auth.confirmSignUp(email, code));
  }

  /**
   * Resend the sign up verification code
   *
   * @todo We must use this in case a user began to sign up,
   *       but then failed to confirm the email, and closed the app.
   *       When they come back, they must be able to resume
   *       the confirmation process where they left.
   *
   * @param email
   */
  resendSignUp(email: string) {
    return from<Promise<string>>(Auth.resendSignUp(email));
  }

  /**
   * Sign Out from Cognito
   *
   * @param global whether to sign out on all devices
   */
  signOut(global?: boolean) {
    return from(Auth.signOut({ global }));
  }

  /**
   * Reset user's password
   * @param email
   */
  resetPassword(email: string) {
    return from<Promise<{ CodeDeliveryDetails: CodeDeliveryDetails }>>(
      Auth.forgotPassword(email),
    );
  }

  private async doConfirmPassword(credentials: Credentials, code: string) {
    const result = await Auth.forgotPasswordSubmit(
      credentials.email,
      code,
      credentials.password,
    );

    return result;
  }

  /**
   * Confirm new user password
   *
   * @param credentials the email and new password
   * @param code the one-time code received via email
   */
  confirmPassword(credentials: Credentials, code: string) {
    return from(this.doConfirmPassword(credentials, code));
  }
}
