import { Route } from '@angular/router';
import { PortfolioReportComponent } from './components/portfolio-report/portfolio-report.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { authGuard } from './services/auth-guard.service';

export enum NavigationRouteNames {
  HOME = 'home',
  SIGN_IN = 'sign-in',
  SIGN_UP = 'sign-up',
}

export const defaultRoute = NavigationRouteNames.HOME;

export const appRoutes: Route[] = [
  {
    path: NavigationRouteNames.HOME,
    canActivate: [authGuard],
    loadComponent: () => PortfolioReportComponent,
  },
  {
    path: NavigationRouteNames.SIGN_IN,
    loadComponent: () => SignInComponent,
  },
  {
    path: NavigationRouteNames.SIGN_UP,
    loadComponent: () => SignUpComponent,
  },
  {
    path: '**',
    redirectTo: defaultRoute,
  },
];
