<mat-card>
  <mat-card-header>
    <mat-card-title>Sign Up</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="signUpForm" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input
          type="email"
          matInput
          formControlName="email"
          placeholder="Ex. pat@example.com"
          required
        />
        <mat-hint>Ex. pat&#64;example.com</mat-hint>
        @if (
          signUpForm.get('email')?.hasError('email') &&
          !signUpForm.get('email')?.hasError('required')
        ) {
          <mat-error>Please enter a valid email address</mat-error>
        }
        @if (signUpForm.get('email')?.hasError('required')) {
          <mat-error>Email is required</mat-error>
        }
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Password</mat-label>
        <input matInput type="password" formControlName="password" required />
        @if (signUpForm.get('password')?.hasError('required')) {
          <mat-error>Password is required</mat-error>
        }
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Confirm Password</mat-label>
        <input
          matInput
          type="password"
          formControlName="confirmPassword"
          required
        />
        @if (signUpForm.get('confirmPassword')?.hasError('required')) {
          <mat-error>Password is required</mat-error>
        }
        @if (
          signUpForm.get('password')?.value !==
          signUpForm.get('confirmPassword')?.value
        ) {
          <mat-error>Use same password to confirm</mat-error>
        }
      </mat-form-field>
      @if (askForCode) {
        <mat-form-field appearance="fill">
          <mat-label>Code</mat-label>
          <input matInput formControlName="code" required />
          @if (!signUpForm.get('code')?.value) {
            <mat-error>Code is required</mat-error>
          }
        </mat-form-field>
      }
      <button
        type="submit"
        mat-raised-button
        class="!block"
        color="primary"
        [disabled]="
          signUpForm.invalid ||
          signUpForm.get('password')?.value !==
            signUpForm.get('confirmPassword')?.value
        "
        >{{ askForCode ? 'Confirm' : 'Sign Up' }}</button
      >
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button [routerLink]="['/', NavigationRouteNames.SIGN_IN]"
      >Go to Sign In</button
    >
  </mat-card-actions>
</mat-card>
