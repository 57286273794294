import type { AuthOptions } from '@aws-amplify/auth/lib-esm/types';
import {
  domainNames,
  SAAS_ACCOUNT_PREFIX,
  YEEKATEE_DOMAIN,
} from '@yeekatee/shared-util-networking';
import type { AmplifyConfig as Base } from 'aws-amplify/lib-esm/Common/types/types';

export type AmplifyConfig = Base & {
  Auth: AuthOptions;
};

export interface Environment {
  readonly production: boolean;
  readonly aws: AmplifyConfig;
  readonly awsAccount: string;
  readonly apiDomainName: string;
}

export type CdkOutput = {
  AwsAccount: string;
  CognitoIdentityPoolId: string;
  CognitoUserPoolId: string;
  CognitoUserPoolWebClientId: string;
  Region: string;
};

export type EnvironmentParams = {
  production: boolean;
};

export const defaultUrls = (account: string) => {
  const { api } = domainNames(YEEKATEE_DOMAIN, account, SAAS_ACCOUNT_PREFIX);
  return {
    api: `https://${api.domainName}`,
  };
};

export const defineEnvironment = (
  {
    AwsAccount,
    CognitoIdentityPoolId,
    CognitoUserPoolId,
    CognitoUserPoolWebClientId,
    Region,
  }: CdkOutput,
  { production }: EnvironmentParams,
): Environment => ({
  production,
  aws: {
    Auth: {
      region: Region,
      userPoolId: CognitoUserPoolId,
      userPoolWebClientId: CognitoUserPoolWebClientId,
      identityPoolId: CognitoIdentityPoolId,
      identityPoolRegion: Region,
      mandatorySignIn: false,
      authenticationFlowType: 'USER_SRP_AUTH',
    },
  },
  awsAccount: AwsAccount,
  apiDomainName: defaultUrls(AwsAccount).api,
});
