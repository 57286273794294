<div class="px-6">
  <h2 class="text-xl font-bold mb-4">Portfolio Report</h2>

  <!-- Configuration Form -->
  <mat-form-field class="mb-4 w-1/3">
    <mat-label>Portfolio ID</mat-label>
    <input matInput type="text" [formControl]="portfolioFormControl" />
  </mat-form-field>

  <mat-form-field class="mb-4 w-1/2 !block">
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input
        (dateInput)="selectStartDate($event)"
        matStartDate
        placeholder="Start date"
      />
      <input
        (dateInput)="selectEndDate($event)"
        matEndDate
        placeholder="End date"
      />
    </mat-date-range-input>
    <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker" />
    <mat-date-range-picker #picker />
  </mat-form-field>

  <button
    (click)="apply()"
    [disabled]="!portfolioFormControl.valid"
    mat-flat-button
    color="primary"
    class="mb-4 !block"
    >Apply</button
  >

  <!-- Chart -->
  <div id="chart"></div>
</div>
